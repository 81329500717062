import {
  GET_PROVIDERS,
  GET_NEXT_PAGE_PROVIDERS,
  GET_PROVIDERS_FILTERED,
  GET_SUGGESTIONS_PROVIDERS,
} from '_modules/provider/actions'

export const providersSelector = state => state.providers
export const hasNextPageSelector = state => state.providers.next
export const providersResultSelector = state =>
  state.providers.order?.map(index => state.providers.getIn(['results', index.toString()]))

export const providersResultWithoutPaginationSelector = state => {
  const hasProviders = state.providers?.order?.length > 0
  const serviceId = state.serviceOrders.get('current')
  const excludeTradesmanId = state.serviceOrders
    .getIn(['results', serviceId.toString()])
    ?.tradesman?.toString()

  if (!hasProviders) {
    return []
  }

  const getProvider = providerId => state.providers.getIn(['results', providerId.toString()])

  return state.providers.order?.reduce((acc, currentProviderId) => {
    if (currentProviderId !== excludeTradesmanId) {
      const provider = getProvider(currentProviderId)

      acc.push({
        id: provider.tradesmanId,
        tradesmanId: provider.tradesmanId,
        name: provider.name,
        mainBlockedAgencies: provider.mainBlockedAgencies,
        blockedContractDate: provider.blockedContractDate,
        contractStatus: provider.contractStatus,
        mainServices: provider.mainServices
          ?.toArray()
          .flat()
          .filter(item => typeof item !== 'string'),
        mainSpecialSkills: provider.mainSpecialSkills?.toJS(),
        score: { ...provider?.score?.toJS(), level: provider?.level },
      })
    }

    return acc
  }, [])
}
export const providersLoadedPagesSelector = state => state.providers.loadedPages

export const providersLoadingSelector = state => !!state.loading.get(GET_PROVIDERS.ACTION)
export const nextPageLoadingSelector = state => !!state.loading.get(GET_NEXT_PAGE_PROVIDERS.ACTION)

export const providerSelector = state =>
  state.providers.results.get(state.providers.currentProvider)
export const currentProviderSelector = state => state.providers.currentProvider
export const providerCountSelector = state => state.providers.count

export const getProvidersLoadingSelector = state =>
  !!state.loading.get(GET_PROVIDERS.ACTION) ||
  !!state.loading.get(GET_NEXT_PAGE_PROVIDERS.ACTION) ||
  !!state.loading.get(GET_PROVIDERS_FILTERED.ACTION)

export const getConnectionSelector = state => state.providers.get('connection').toJS()

export const getProviderSuggestionsListSelector = state => state.providers.providerSuggestionsList
export const getProviderSuggestionsListSelectorLoading = state =>
  !!state.loading.get(GET_SUGGESTIONS_PROVIDERS.ACTION)

export const providerSuggestionsSelector = state => state.providers.suggestedProvidersBody

export const getProviderOrdersId = state => state.providers.get('providerOrdersId')
